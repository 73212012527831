import { ReduxState } from "@/redux";
import { ProgressState, ProgressStatus, QuestionsAnswersMap } from "./types";
import { createSelector } from "@reduxjs/toolkit";
import { quizSelectors } from "../quiz/selectors";
import { QuizQuestion } from "../quiz/types";

const state = (state: ReduxState): ProgressState => {
  return state.progress;
};

const answersMap = (state: ReduxState): QuestionsAnswersMap => {
  return state.progress.answersMap;
};

const status = (state: ReduxState): ProgressStatus => {
  return state.progress.status;
};

const startedAt = (state: ReduxState): Date | undefined => {
  return state.progress.startedAt;
};

const finishedAt = (state: ReduxState): Date | undefined => {
  return state.progress.finishedAt;
};

const showExplanation = (state: ReduxState): boolean => {
  return state.progress.showExplanation;
};

const currentAnswers = createSelector(quizSelectors.question, answersMap, (question, answersMap): string[] => {
  if (!question) {
    return [];
  }
  return answersMap[question.id] || [];
});

const correctQuestions = createSelector(
  quizSelectors.questions,
  answersMap,
  (_questions, _answersMap): QuizQuestion[] => {
    return _questions.filter((question) => {
      const correctAnswerIds = question.answers.filter((answer) => answer.is_correct).map((answer) => answer.id);
      const userAnswerIds = _answersMap[question.id] || [];
      if (
        correctAnswerIds.length === userAnswerIds.length &&
        correctAnswerIds.every((value, _index) => userAnswerIds.includes(value))
      ) {
        return true;
      }
    });
  }
);

const currentQuestionIsCorrect = createSelector(
  quizSelectors.question,
  correctQuestions,
  (_question, _correctQuestions): boolean => {
    return !!_question && _correctQuestions.findIndex((q) => q.id == _question.id) != -1;
  }
);

const correctQuestionsCount = createSelector(correctQuestions, (_questions): number => {
  return _questions.length;
});

const correctQuestionsPercent = createSelector(
  correctQuestionsCount,
  quizSelectors.questions,
  (_correctQuestionsCount, _questions) => {
    return Math.round((_correctQuestionsCount / _questions.length) * 100);
  }
);

const timeSpent = createSelector(status, startedAt, finishedAt, (_status, _startedAt, _finishedAt) => {
  if (_status !== ProgressStatus.FINISHED || !_startedAt || !_finishedAt) {
    return 0;
  }
  return new Date(_finishedAt).getTime() - new Date(_startedAt).getTime();
});

const timeSpentMinutes = createSelector(timeSpent, (_timeSpent) => {
  return Math.round(_timeSpent / 1000 / 60);
});

const unansweredQuestions = createSelector(quizSelectors.questions, answersMap, (_questions, _answersMap) => {
  return _questions.filter((question) => {
    return !_answersMap[question.id];
  });
});

const answeredQuestionsCount = createSelector(answersMap, (_answersMap) => {
  return Object.keys(_answersMap).length;
});

export const progressSelectors = {
  state,
  answersMap,
  answeredQuestionsCount,
  status,
  currentAnswers,
  showExplanation,
  correctQuestions,
  correctQuestionsCount,
  correctQuestionsPercent,
  currentQuestionIsCorrect,
  timeSpent,
  timeSpentMinutes,
  unansweredQuestions,
};

import { AxiosInstance } from "axios";
import React, { ReactNode, useContext  } from "react";

export const ApiContext = React.createContext<null | AxiosInstance>(null);

type ApiProviderProps = {
  client: AxiosInstance | null;
  children?: ReactNode;
};

export const ApiProvider: React.FC<ApiProviderProps> = ({ client, children }) => {
  return <ApiContext.Provider value={client}>{children}</ApiContext.Provider>;
};

export type ApiProps = {
  api: AxiosInstance;
};

export function useApi(): AxiosInstance {
  const client = useContext(ApiContext);
  if (client) return client;
  else throw Error("API client not found in context");
}

import { select, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { event } from "nextjs-google-analytics";
import { isServer } from "@/core/utils/nextjs";
import getConfig from "next/config";
import { progressActions } from "../progress/actions";
import { quizSelectors } from "../quiz/selectors";
import { Quiz } from "../quizzes/types";
import { GAEvent } from "./types";

const { publicRuntimeConfig } = getConfig();

function* quizTriggerEvent(e: GAEvent): Generator {
  if (isServer()) return null;
  if (!publicRuntimeConfig.GOOGLE_ANALYTICS_ID) return null;

  const quiz = (yield select(quizSelectors.quiz)) as Quiz;
  if (!quiz) {
    return;
  }

  event(e.action, {
    category: e.options.category,
    label: e.options.label || quiz.title,
    value: e.options.value,
    nonInteraction: e.options.nonInteraction,
    userId: e.options.userId,
  });
}

function* quizResetProgress(): Generator {
  const gaEvent = {
    action: "quiz_progress_reset",
    options: {
      category: "Progress",
      label: "Reset",
    },
  };
  yield quizTriggerEvent(gaEvent);
}

function* quizChangeQuestion(): Generator {
  const gaEvent = {
    action: "quiz_progress_update",
    options: {
      category: "Progress",
      label: "Update",
    },
  };
  yield quizTriggerEvent(gaEvent);
}

function* quizComplete(): Generator {
  const gaEvent = {
    action: "quiz_progress_complete",
    options: {
      category: "Progress",
      label: "Complete",
    },
  };
  yield quizTriggerEvent(gaEvent);
}

export default function* sagas(): Generator {
  // reset progress on quiz reset
  yield takeLatest(progressActions.reset, quizResetProgress);

  // send progress to server on reset, or answers changed
  yield takeLatest(progressActions.toggleMultiAnswer, quizChangeQuestion);
  yield takeLatest(progressActions.setSingleAnswer, quizChangeQuestion);
  yield takeLatest(progressActions.complete, quizComplete);
}

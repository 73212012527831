import { createAction } from "@reduxjs/toolkit";
import { flashcardsReducerSlice } from "./slices";

const initialize = createAction("flashcards/initialize");
const loadMore = createAction("flashcards/loadMore");

export const flashcardsActions = {
  initialize,
  loadMore,
  ...flashcardsReducerSlice.actions,
};

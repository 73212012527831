import { createAction } from "@reduxjs/toolkit";
import { featuredReducerSlice } from "./slices";

export type LoadQuestionPayload = {
  reload?: boolean;
} | undefined

const loadQuestion = createAction<LoadQuestionPayload>("featured/load");

export const featuredActions = {
  loadQuestion,
  ...featuredReducerSlice.actions,
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { QuizQuestion, QuizState, QuizStatus } from "./types";
import { Quiz } from "../quizzes/types";
import { QuizProgressInfo } from "../progress/types";

const getInitialState = (): QuizState => {
  return {
    quiz: undefined,
    quizStatus: QuizStatus.LOADING,
    quizStatusCode: undefined,

    questions: [],
    activeQuestionSlug: undefined,

    relatedQuizzes: undefined,
  };
};

export const quizReducerSlice = createSlice({
  name: "quiz",
  initialState: getInitialState(),
  reducers: {
    reset: (state) => {
      state.quiz = undefined;
      state.quizStatus = QuizStatus.LOADING;
      state.relatedQuizzes = undefined;
    },
    setQuiz(state, action: PayloadAction<Quiz>) {
      state.quiz = action.payload;
    },
    setQuizStatus(state, action: PayloadAction<{ status: QuizStatus; code?: number | undefined }>) {
      state.quizStatus = action.payload.status;
      state.quizStatusCode = action.payload.code;
    },
    setQuestions(state, action: PayloadAction<QuizQuestion[]>) {
      state.questions = action.payload;
    },
    setActiveQuestionSlug: (state, action: PayloadAction<string | undefined>) => {
      state.activeQuestionSlug = action.payload;
    },
    setRelatedQuizzes: (state, action: PayloadAction<Quiz[] | undefined>) => {
      state.relatedQuizzes = action.payload;
    },
    setProgressInfo: (state, action: PayloadAction<QuizProgressInfo>) => {
      if (!state.quiz) return;
      state.quiz = {
        ...state.quiz,
        progress: action.payload,
      };
    },
  },
});

import { Quiz } from "../quizzes/types";
import { components } from "../../../api/types";

export interface QuizState {
  quiz?: Quiz;
  quizStatus: QuizStatus;
  quizStatusCode?: number | undefined;
  questions: QuizQuestion[];

  activeQuestionSlug?: string;
  relatedQuizzes?: Quiz[];
}
export enum QuizStatus {
  LOADING = "LOADING",
  LOADED = "LOADED",
  ERROR = "ERROR",
}

export type QuizQuestion = Record<string, any> & {
  answers: QuizAnswer[];
  type: string;
};
export type QuizAnswer = Record<string, any>;
export type QuizDetails = components["schemas"]["QuizDetails"];

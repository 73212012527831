import { ReduxState } from "@/redux";
import { AuthStatus, Guest, Token, User } from "./types";

const status = (state: ReduxState): AuthStatus => {
  return state.auth.status;
};

const guest = (state: ReduxState): Guest | undefined => {
  return state.auth.guest;
};

const user = (state: ReduxState): User | undefined => {
  return state.auth.user;
};

const token = (state: ReduxState): Token | undefined => {
  return state.auth.token;
};

export const authSelectors = {
  guest,
  user,
  token,
  status,
};

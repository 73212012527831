import { createAction } from "@reduxjs/toolkit";
import { appReducerSlice } from "./slices";
import { ParsedUrlQuery } from "querystring";

export type InitializePayload = {
  withEndSaga?: boolean;
  routerRoute?: string;
  routerQuery?: ParsedUrlQuery;
  hostname?: string;
} | undefined

const initialize = createAction<InitializePayload>("app/initialize");

export const appActions = {
  initialize,
  ...appReducerSlice.actions,
};

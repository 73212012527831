import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { LoadQuestionPayload, featuredActions } from "./actions";
import { getApiClient } from "../api/sagas";
import { AxiosInstance } from "axios";
import { featuredSelectors } from "./selectors";
import { QuizQuestion } from "../quiz/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { QuestionStatus } from "./types";

function* onLoad(action: PayloadAction<LoadQuestionPayload>): Generator {
  const question = (yield select(featuredSelectors.question)) as QuizQuestion;

  // If we already have a question, dont' reload unless explicit
  if (!question || action.payload?.reload) {
    yield loadNewQuestion();
  }
}

function* loadNewQuestion(): Generator {
  const api = (yield getApiClient()) as AxiosInstance;
  try {
    const data: any = yield call(api.get, "/api/quizzes/featured/questions/");
    yield put(featuredActions.setQuestion(data.data.results[0]));
    yield put(featuredActions.setQuestionStatus(QuestionStatus.LOADED));
  } catch (error) {
    yield put(featuredActions.setQuestionStatus(QuestionStatus.ERROR));
  }
}

function* loadNewQuestionLater(): Generator {
  while (true) {
    yield delay(1000 * 60 * 5);
    yield loadNewQuestion();
  }
}

export default function* sagas(): Generator {
  yield takeLatest(featuredActions.loadQuestion, onLoad);
  yield takeLatest(featuredActions.setQuestion, loadNewQuestionLater);
}

import { Store, configureStore } from "@reduxjs/toolkit";
import { createLoggerMiddleware } from "./middleware/logger";
import { rootReducer } from "./store/reducers";
import createSagaMiddleware, { Task } from "redux-saga";
import rootSaga from "./store/sagas";
import { createWrapper } from "next-redux-wrapper";

export interface SagaStore extends Store {
  sagaTask: Task;
}

export function createReduxStore() {
  const sagaMiddleware = createSagaMiddleware();
  const loggerMiddleware = createLoggerMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        thunk: false,
      })
        .concat(loggerMiddleware)
        .concat(sagaMiddleware);
    },
    devTools: false,
  });

  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  return store as SagaStore;
}

export type ReduxStore = ReturnType<typeof createReduxStore>;
export type ReduxState = ReturnType<ReduxStore["getState"]>;

export const storeWrapper = createWrapper(createReduxStore, { debug: false });

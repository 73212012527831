import { createAction } from "@reduxjs/toolkit";
import { progressReducerSlice } from "./slices";
import { QuizProgressInfo } from "./types";

const quizProgressInfoUpdated = createAction<QuizProgressInfo>("quiz/quizProgressInfoUpdated");

export const progressActions = {
  quizProgressInfoUpdated,
  ...progressReducerSlice.actions,
};

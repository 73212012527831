import { call, put, select, takeEvery, takeLatest, takeMaybe } from "redux-saga/effects";
import { flashcardsActions } from "./actions";
import { flashcardsSelectors } from "./selectors";
import { FlashCard, FlashCardsListStatus } from "./types";
import { getApiClient } from "../api/sagas";
import { AxiosInstance } from "axios";

function* onInitialize(): Generator {
  //select loading status from state
  const status = yield select(flashcardsSelectors.flashcardsListStatus);
  if (status === FlashCardsListStatus.LOADING) {
    try {
      yield loadFlashCards();
    } catch (error) {
      console.error(error);
      yield put(flashcardsActions.setListStatus(FlashCardsListStatus.ERROR));
    }
  }
}

function* onLoadMore(): Generator {
  //select loading status from state
  const status = yield select(flashcardsSelectors.flashcardsListStatus);
  if (status !== FlashCardsListStatus.LOADING && status !== FlashCardsListStatus.LOADING_MORE) {
    yield put(flashcardsActions.setListStatus(FlashCardsListStatus.LOADING_MORE));
    try {
      yield loadFlashCards();
    } catch (error) {
      console.error(error);
      yield put(flashcardsActions.setListStatus(FlashCardsListStatus.ERROR));
    }
  }
}

function* loadFlashCards(): Generator {
  try {
    const api = (yield getApiClient()) as AxiosInstance;
    const list = (yield select(flashcardsSelectors.flashcardsList)) as FlashCard[];
    const data: any = yield call(api.get, "/api/flashcards/flashcards/", { params: { limit: 12, offset: list.length } });
    yield put(flashcardsActions.appendList(data.data.results));
    yield put(flashcardsActions.setListStatus(FlashCardsListStatus.LOADED));
    yield put(flashcardsActions.setHasMore(!!data.data.next));
  } catch (error) {
    console.error(error);
    yield put(flashcardsActions.setListStatus(FlashCardsListStatus.ERROR));
  }
}



export default function* sagas(): Generator {
  yield takeLatest(flashcardsActions.initialize, onInitialize);
  yield takeEvery(flashcardsActions.loadMore, onLoadMore);

}

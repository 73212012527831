import { call, put, takeLatest } from "redux-saga/effects";
import { InitializePayload, siteActions } from "./actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { createApiInstance } from "@/core/api/backend";
import { SiteCodeInvalid, SiteStatus } from "./types";
import { AxiosError, isAxiosError } from "axios";


function* onInitialize(action: PayloadAction<InitializePayload>): Generator {
  const domain = action.payload.domain;
  try {
    const api = createApiInstance();
    // Cache this data
    const { data }: any = yield call(api.get, `/api/sites/domain/${domain}/`);
    yield put(siteActions.setCodeWithConfig({ code: data.site.code, config: data.public_config.site }));
    yield put(siteActions.setStatus(SiteStatus.CONFIGURED));
  }
  catch (err) {
    if (isAxiosError(err) && err.response?.status == 404) {
      yield put(siteActions.setStatus(SiteStatus.INVALID));
    }
    else {
      yield put(siteActions.setStatus(SiteStatus.ERROR));
    }
  }
}


export default function* sagas(): Generator {
  yield takeLatest(siteActions.initialize, onInitialize);
}

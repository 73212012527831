import { components } from "@/core/api/types";

export interface ProgressState {
  answersMap: QuestionsAnswersMap;
  status: ProgressStatus;
  showExplanation: boolean;
  startedAt?: string;
  finishedAt?: string;
}
export enum ProgressStatus {
  STARTED = "STARTED",
  FINISHED = "FINISHED",
}

export type QuestionsAnswersMap = Record<string, string[]>; // questionId -> answerIds
export type QuizProgressInfo =
  | components["schemas"]["GuestQuizProgressInfo"]
  | null;

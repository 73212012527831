import { call, cancel, delay, fork, put, select, takeLatest } from "redux-saga/effects";
import { Action, PayloadAction } from "@reduxjs/toolkit";
import { quizzesActions } from "./actions";
import { Quiz, QuizzesListStatus } from "./types";
import { filtersSelectors } from "../filters/selectors";
import { getApiClient } from "../api/sagas";
import { AxiosInstance } from "axios";
import { progressActions } from "../progress/actions";
import { quizSelectors } from "../quiz/selectors";
import { quizzesSelectors } from "./selectors";
import { isClient } from "@/core/utils/nextjs";
import { QuizProgressInfo } from "../progress/types";

function* onInitialize(): Generator {
  const status = yield select(quizzesSelectors.quizzesListStatus);
  if (status === QuizzesListStatus.LOADING) {
    try {
      yield onFetchList();
    } catch (error) {
      console.error(error);
      yield put(quizzesActions.setListStatus(QuizzesListStatus.ERROR));
    }
  }
}

function* onFetchList(): Generator {
  const api = (yield getApiClient()) as AxiosInstance;
  const sorting = yield select(filtersSelectors.sorting);
  const not_taken = yield select(filtersSelectors.not_taken);
  const difficulty = yield select(filtersSelectors.difficulty);

  const data: any = yield call(api.get, "/api/quizzes/quizzes/", {
    params: {
      ordering: sorting,
      is_not_taken: not_taken,
      difficulty: difficulty,
    },
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });

  yield put(quizzesActions.setList(data.data.results as Quiz[]));
  yield put(quizzesActions.setListStatus(QuizzesListStatus.READY));
}

function* onQuizProgressInfo(action: PayloadAction<QuizProgressInfo>): Generator {
  const quiz = (yield select(quizSelectors.quiz)) as Quiz;
  if (!quiz) return;
  yield put(quizzesActions.updateQuizProgressInfo({ quiz, progress: action.payload }));
}

export default function* sagas(): Generator {
  yield takeLatest(quizzesActions.initialize, onInitialize);
  yield takeLatest(quizzesActions.fetchList, onFetchList);

  yield takeLatest(progressActions.quizProgressInfoUpdated, onQuizProgressInfo);
}

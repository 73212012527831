import { call, put, select, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { InitializePayload, authActions } from "./actions";
import { createApiInstance } from "@/core/api/backend";
import { AuthStatus, Guest, Token, TokenType, User } from "./types";
import { uuidv4 } from "@/core/utils/string";
import { contextSelectors } from "../context/selectors";
import { GUEST_COOKIE_KEY, USER_COOKIE_KEY } from "@/core/utils/auth";
import { contextActions } from "../context/actions";
import { authSelectors } from "./selectors";
import { siteSelectors } from "../site/selectors";

function* onInitialize(action: PayloadAction<InitializePayload>): Generator {
  const status = yield select(authSelectors.status);
  if (status == AuthStatus.NA) {
    // Try to init user
    const user_token = yield select(contextSelectors.requestCookie, USER_COOKIE_KEY);
    if (user_token) {
      try {
        yield resumeUserToken(user_token as string);
        yield put(authActions.setStatus(AuthStatus.READY));
        return; // STOP HERE
      } catch (e) {
        console.error(e);
        yield put(contextActions.deleteCookie(USER_COOKIE_KEY));
      }
    }
    // Try to init guest
    const createIfMissing = action.payload?.forceGuest
    const guest_token = yield getGuest(createIfMissing);
    if (guest_token) {
      yield resumeGuest(guest_token as string);
      yield put(authActions.setStatus(AuthStatus.READY));
    }
    else {
      yield put(authActions.setStatus(AuthStatus.NA));
    }
  }
}

function* getGuest(createIfMissing: boolean = false): Generator {
  let cg = yield select(contextSelectors.requestCookie, GUEST_COOKIE_KEY);
  if (!cg && createIfMissing) {
    cg = uuidv4();
    yield put(contextActions.setCookie({ key: GUEST_COOKIE_KEY, value: cg as string }));
  }
  return cg;
}

function* resumeUserToken(token_value: string): Generator {
  const siteCode = (yield select(siteSelectors.siteCode)) as string;
  if (!siteCode) {
    console.error("No site code found in store");
  }
  const token: Token = {
    token: token_value,
    type: TokenType.USER,
  };
  const api = createApiInstance({ token, siteCode });
  const data: any = yield call(api.get, `/api/users/account`);
  yield put(authActions.setToken(token));
  yield put(authActions.setUser(data.data as User));
}

function* resumeGuest(taoken_value: string): Generator {
  const guest: Guest = {
    name: "Guest"
  };
  const token: Token = {
    token: taoken_value,
    type: TokenType.GUEST,
  };
  yield put(authActions.setToken(token));
  yield put(authActions.setGuest(guest));
}

export default function* sagas(): Generator {
  yield takeLatest(authActions.initialize, onInitialize);
}

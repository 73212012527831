import { createAction } from "@reduxjs/toolkit";
import { quizzesReducerSlice } from "./slices";


const initialize = createAction("quizzes/initialize");

const fetchList = createAction("quizzes/fetchList");



export const quizzesActions = {
  initialize,
  fetchList,
  ...quizzesReducerSlice.actions,
};

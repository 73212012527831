import { ReduxState } from "@/redux";
import { QuestionStatus } from "./types";
import { QuizQuestion } from "../quiz/types";

const question = (state: ReduxState): QuizQuestion => {
  return state.featured.question;
};

const questionStatus = (state: ReduxState): QuestionStatus => {
  return state.featured.questionStatus;
};


export const featuredSelectors = {
  question,
  questionStatus,
}
import { ReduxState } from "@/redux";
import { SiteConfig, SiteStatus } from "./types";

const siteCode = (state: ReduxState): string | undefined => {
  return state.site.code;
};

const siteStatus = (state: ReduxState): SiteStatus => {
  return state.site.status;
}

const siteConfig = (state: ReduxState): SiteConfig => {
  return state.site.config;
};

export const siteSelectors = {
  siteCode,
  siteStatus,
  siteConfig,
};

import appSagas from "./app/sagas";
import quizzesSagas from "./quizzes/sagas";
import featuredSagas from "./featured/sagas";
import filtersSagas from "./filters/sagas";
import quizSagas from "./quiz/sagas";
import authSagas from "./auth/sagas";
import progressSagas from "./progress/sagas";
import contextSagas from "./context/sagas";
import routerSagas from "./router/sagas";
import analyticsSagas from "./analytics/sagas";
import siteSagas from "./site/sagas";
import flashcardsSagas from "./flashcards/sagas";
import { all } from "redux-saga/effects";

export default function* rootSaga() {
  yield all([
    appSagas(),
    quizzesSagas(),
    featuredSagas(),
    filtersSagas(),
    quizSagas(),
    authSagas(),
    progressSagas(),
    contextSagas(),
    routerSagas(),
    analyticsSagas(),
    siteSagas(),
    flashcardsSagas(),
  ]);
}

export interface AuthState {
  status: AuthStatus;
  user?: User;
  guest?: Guest;
  token?: Token;
}

export type User = any;

export type Guest = any;

export enum TokenType {
  USER = "user",
  GUEST = "guest",
}

export type Token = {
  token: string;
  type: TokenType;
};

export enum AuthStatus {
  NA = "NA",
  READY = "READY",
  ERROR = "ERROR",
}

import { QuizQuestion } from "../quiz/types";

export interface QuestionState {
  question?: QuizQuestion;
  questionStatus: QuestionStatus;
}
export enum QuestionStatus {
  LOADING = "LOADING",
  LOADED = "LOADED",
  ERROR = "ERROR",
}


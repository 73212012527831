import { delay, put, race, select, take } from "redux-saga/effects";
import { authSelectors } from "../auth/selectors";
import { createApiInstance } from "@/core/api/backend";
import { AuthStatus, Token } from "../auth/types";
import { ReduxState } from "../..";
import { authActions } from "../auth/actions";
import { siteSelectors } from "../site/selectors";

export function* getApiClient(force_authentication = false): Generator {
  const status = (yield select<ReduxState>(authSelectors.status)) as AuthStatus;
  if (status != AuthStatus.READY) {
    if (force_authentication) {
      yield forceGuestAuthentiction();
    }
  }
  const token = (yield select<ReduxState>(authSelectors.token)) as Token | undefined;
  const siteCode = (yield select<ReduxState>(siteSelectors.siteCode)) as string;
  if (!siteCode) {
    console.error("No site code found in state");
  }
  return createApiInstance({ token, siteCode });
}

function* forceGuestAuthentiction(): Generator {
  yield put(authActions.initialize({ forceGuest: true }));
  yield race({
    ready: take(authActions.setStatus),
    timeout: delay(1000),
  });
}

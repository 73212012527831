import { components } from "@/core/api/types";

export interface QuizzesState {
  list: Quiz[];
  listStatus: QuizzesListStatus;
}
export enum QuizzesListStatus {
  LOADING = "LOADING",
  RELOADING = "RELOADING",
  READY = "READY",
  ERROR = "ERROR",
}

export type Quiz = components["schemas"]["Quiz"];
export type QuizDificulty = components["schemas"]["DifficultyEnum"];

export const AppLoading: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="grow py-6">
        <main className="grid min-h-full place-items-center px-6 py-24 sm:py-48 lg:px-8">
          <div className="text-center">
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Loading ...</h1>
          </div>
        </main>
      </main>
    </div>
  );
};

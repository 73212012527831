import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Quiz, QuizzesListStatus, QuizzesState } from "./types";
import { QuizProgressInfo } from "../progress/types";

const getInitialState = (): QuizzesState => {
  return {
    list: [],
    listStatus: QuizzesListStatus.LOADING,
  };
};

export const quizzesReducerSlice = createSlice({
  name: "quizzes",
  initialState: getInitialState(),
  reducers: {
    setList: (state, action: PayloadAction<Quiz[]>) => {
      state.list = action.payload;
    },
    setListStatus: (state, action: PayloadAction<QuizzesListStatus>) => {
      state.listStatus = action.payload;
    },
    updateQuizProgressInfo: (state, action: PayloadAction<{ quiz: Quiz; progress: QuizProgressInfo }>) => {
      const { quiz, progress } = action.payload;

      const quizIndex = state.list.findIndex((q) => q.id === quiz.id);
      if (quizIndex === -1) return;
      const newQuizzesList = [...state.list];
      newQuizzesList[quizIndex] = {
        ...quiz,
        progress: progress,
      };
      state.list = newQuizzesList;
    },
  },
});

import { createAction } from "@reduxjs/toolkit";
import { quizReducerSlice } from "./slices";


export type InitializeQuizPayload = {
  quizSlug: string;
  questionSlug?: string;
}

const initializeQuiz = createAction<InitializeQuizPayload>("quiz/initializeQuiz");

const loadRelatedQuizzes = createAction("quiz/loadRelatedQuizzes");


export const quizActions = {
  initializeQuiz,
  loadRelatedQuizzes,
  ...quizReducerSlice.actions,
};

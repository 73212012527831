export const USER_COOKIE_KEY = "__token";
export const GUEST_COOKIE_KEY = "__gutoken";

// export const getToken = (ctx?: NextPageContext): string | null => {
//   return getCookie(ctx, "__token");
// };

// export const getGuestToken = (ctx?: NextPageContext): string | null => {
//   return getCookie(ctx, "__gutoken");
// };

// export const saveToken = (token: string, ctx?: NextPageContext): void => {
//   saveCookie(ctx, "__token", token);
// };

// export const saveGuestToken = (token: string, ctx?: NextPageContext): void => {
//   saveCookie(ctx, "__gutoken", token);
// };

// export const deleteToken = (ctx?: NextPageContext): void => {
//   deleteCookie(ctx, "__token");
// };

// export const deleteGuestToken = (ctx?: NextPageContext): void => {
//   deleteCookie(ctx, "__gutoken");
// };

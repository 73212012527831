import { call, put, select, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { getApiClient } from "../api/sagas";
import { AxiosInstance, AxiosResponse } from "axios";
import { progressActions } from "./actions";
import { progressSelectors } from "./selectors";
import { quizSelectors } from "../quiz/selectors";
import { quizActions } from "../quiz/actions";
import { Quiz } from "../quizzes/types";
import { ProgressStatus, QuestionsAnswersMap, QuizProgressInfo } from "./types";

function* saveProgress(): Generator {
  // debounce this action
  const api = (yield getApiClient(true)) as AxiosInstance;
  const quiz = (yield select(quizSelectors.quiz)) as Quiz;
  if (!quiz) {
    return;
  }
  const answersMap = (yield select(progressSelectors.answersMap)) as QuestionsAnswersMap;
  const correctQuestionsCount = (yield select(progressSelectors.correctQuestionsCount)) as number;
  const questionsCount = (yield select(quizSelectors.questionsCount)) as number;
  const status = (yield select(progressSelectors.status)) as ProgressStatus;
  const answeredQuestionsCount = (yield select(progressSelectors.answeredQuestionsCount)) as number;

  // We dont' save progress unless a question is answered
  if (Object.keys(answersMap).length === 0 && status != ProgressStatus.FINISHED) {
    return;
  }

  const progressInfo: QuizProgressInfo = {
    questions_total: questionsCount,
    questions_answered: answeredQuestionsCount,
    questions_answered_correct: correctQuestionsCount,
    status: status === ProgressStatus.FINISHED ? "completed" : "in_progress",
  };

  yield put(progressActions.quizProgressInfoUpdated(progressInfo));

  try {
    yield call(api.patch, `/api/progress/quizzes/${quiz.id}/`, {
      answers: answersMap,
      ...progressInfo,
    });
  } catch (error) {
    console.error(error);
  }
}

function* resumeProgress(action: PayloadAction<Quiz>): Generator {
  // We preffer to use the non-authenticated api client here, as we might not have a progress
  const quiz = action.payload;
  if (!quiz) return;
  if (!quiz.progress) {
    yield put(progressActions.clear());
    return;
  }

  const api = (yield getApiClient(true)) as AxiosInstance;
  try {
    const data = (yield call(api.get, `/api/progress/quizzes/${quiz.id}/`)) as AxiosResponse;
    const status = data.data.status == "in_progress" ? ProgressStatus.STARTED : ProgressStatus.FINISHED;
    yield put(
      progressActions.resume({
        answersMap: data.data.answers,
        status: status,
      })
    );
  } catch (error: any) {
    if (error?.response?.status !== 404) {
      console.error(error);
    }
  }
}

function* resetProgress(): Generator {
  const api = (yield getApiClient(true)) as AxiosInstance;
  const quiz = (yield select(quizSelectors.quiz)) as Quiz;
  if (!quiz) return;

  yield put(progressActions.quizProgressInfoUpdated(null));

  try {
    yield call(api.delete, `/api/progress/quizzes/${quiz.id}/`);
  } catch (error) {
    console.error(error);
  }
}

export default function* sagas(): Generator {
  // resume progress on quiz start
  yield takeLatest(quizActions.setQuiz, resumeProgress);

  // reset progress on quiz reset
  yield takeLatest(progressActions.reset, resetProgress);

  // send progress to server on reset, or answers changed
  yield takeLatest(progressActions.toggleMultiAnswer, saveProgress);
  yield takeLatest(progressActions.setSingleAnswer, saveProgress);
  yield takeLatest(progressActions.complete, saveProgress);
}

import { takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { routerActions } from "./actions";

function* onSetUrl(action: PayloadAction<string | undefined>): Generator {
  // console.error("onSetUrl", action.payload);
}

export default function* sagas(): Generator {
  yield takeLatest(routerActions.setUrl, onSetUrl);
}

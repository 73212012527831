import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RouterState } from "./types";

const getInitialState = (): RouterState => {
  return {
    url: undefined,
    previousUrl: undefined,
  };
};

export const routerReducerSlice = createSlice({
  name: "router",
  initialState: getInitialState(),
  reducers: {
    setUrl: (state, action: PayloadAction<string | undefined>) => {
      if (state.url != action.payload) {
        state.previousUrl = state.url;
        state.url = action.payload;
      }
    },
  },
});

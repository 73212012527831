import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContextState } from "./types";
import { CookieKey, CookieValue } from "@/core/utils/cookies";

const getInitialState = (): ContextState => {
  return {
    requestCookies: {},
    responseCookies: {},
  };
};

export const contextReducerSlice = createSlice({
  name: "context",
  initialState: getInitialState(),
  reducers: {
    setInitialCookies: (state, action: PayloadAction<ContextState["requestCookies"]>) => {
      state.requestCookies = action.payload;
      state.responseCookies = action.payload;
    },
    setCookie: (state, action: PayloadAction<{ key: CookieKey; value: CookieValue; }>
    ) => {
      const { key, value } = action.payload;
      state.responseCookies[key] = value;
    },
    deleteCookie: (state, action: PayloadAction<CookieKey>) => {
      const key = action.payload;
      delete state.responseCookies[key];
    },
  },
});

import nookies, { parseCookies, setCookie } from "nookies";
import { NextPageContext } from "next/types";

export type CookieKey = string;
export type CookieValue = string | number | boolean | undefined;
export type Cookies = Record<string, CookieValue>;

export const getCookie = (ctx: NextPageContext | undefined, key: string): CookieValue => {
  if (ctx && ctx.req) {
    const cookies = nookies.get(ctx);
    return cookies[key];
  } else {
    const cookies = parseCookies();
    return cookies[key];
  }
};

export const getCookies = (ctx?: NextPageContext | undefined): Cookies => {
  if (ctx && ctx.req) {
    const cookies = nookies.get(ctx);
    return cookies;
  } else {
    const cookies = parseCookies();
    return cookies;
  }
};

export const saveCookie = (
  ctx: NextPageContext | undefined,
  key: string,
  value?: CookieValue
): void => {
  if (!value) {
    deleteCookie(ctx, key);
    return;
  }
  if (ctx && ctx.req) {
    nookies.set(ctx, key, String(value), {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
  } else {
    setCookie(null, key, String(value), {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
  }
};

export const deleteCookie = (ctx: NextPageContext | undefined, key: string): void => {
  if (ctx && ctx.req) {
    nookies.destroy(ctx, key);
  } else {
    //destroyCookie ?
    setCookie(null, key, "", {
      maxAge: -1,
      path: "/",
    });
  }
};

import { put, takeLatest } from "redux-saga/effects";
import { filtersActions } from "./actions";
import { quizzesActions } from "../quizzes/actions";
import { Action } from "@reduxjs/toolkit";

function* onFiltersChange(action: Action): Generator {
  yield put(quizzesActions.fetchList());
}

export default function* sagas(): Generator {
  yield takeLatest(filtersActions.setSorting, onFiltersChange);
  yield takeLatest(filtersActions.setDifficulty, onFiltersChange);
  yield takeLatest(filtersActions.setNotTaken, onFiltersChange);
  yield takeLatest(filtersActions.resetFilters, onFiltersChange);
}

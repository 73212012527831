import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlashCard, FlashCardsListStatus, FlashCardsState } from "./types";

const getInitialState = (): FlashCardsState => {
  return {
    list: [],
    hasMore: false,
    listStatus: FlashCardsListStatus.LOADING,
    card: undefined,
  };
};

export const flashcardsReducerSlice = createSlice({
  name: "flashcards",
  initialState: getInitialState(),
  reducers: {
    setCard: (state, action: PayloadAction<FlashCard | undefined>) => {
      state.card = action.payload;
    },
    setList: (state, action: PayloadAction<FlashCard[]>) => {
      state.list = action.payload;
    },
    appendList: (state, action: PayloadAction<FlashCard[]>) => {
      state.list = [...state.list, ...action.payload];
    },
    setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload;
    },
    setListStatus: (state, action: PayloadAction<FlashCardsListStatus>) => {
      state.listStatus = action.payload;
    },
  },
});

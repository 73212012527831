import { ReduxState } from "@/redux";

const sorting = (state: ReduxState): string => {
  return state.filters.sorting;
};

const difficulty = (state: ReduxState): string[] => {
  return state.filters.difficulty;
};

const not_taken = (state: ReduxState): boolean | null => {
  return state.filters.not_taken;
};

export const filtersSelectors = {
  sorting,
  difficulty,
  not_taken,
};

import { delay, put, race, select, take, takeLatest } from "redux-saga/effects";
import { END } from "@redux-saga/core";
import { PayloadAction } from "@reduxjs/toolkit";
import { InitializePayload, appActions } from "./actions";
import { AppStatus } from "./types";
import { quizzesActions } from "../quizzes/actions";
import { appSelectors } from "./selectors";
import { authActions } from "../auth/actions";
import { ParsedUrlQuery } from "querystring";
import { quizActions } from "../quiz/actions";
import { SiteStatus } from "../site/types";
import { siteActions } from "../site/actions";
import { flashcardsActions } from "../flashcards/actions";

function* onInitialize(action: PayloadAction<InitializePayload>): Generator {
  const status = (yield select(appSelectors.appStatus)) as AppStatus;

  // Init the app if needed
  if (status == AppStatus.LOADING) {
    try {

      const hostname = action.payload?.hostname as string

      // Init auth
      const raced1: any = yield race({
        status: initSite(hostname),
        timeout: delay(5000), //5s
      });
      if (raced1.timeout) {
        throw new Error("timeout on initSite")
      }
      if (raced1.status != SiteStatus.CONFIGURED) {
        throw new Error("Failed to initSite")
      }

      // Init auth
      const raced2: any = yield race({
        ready: initAuth(),
        timeout: delay(10000), //10s
      });
      if (raced2.timeout) {
        console.log("timeout on initAuth");
      }

      // Mark app as ready
      yield put(appActions.setStatus(AppStatus.READY));
    } catch (error) {
      yield put(appActions.setStatus(AppStatus.ERROR));
    }
  } else {
    yield put(appActions.setStatus(status));
  }


  // Preload content
  if (action.payload?.routerRoute && action.payload?.routerQuery) {
    const raced: any = yield race({
      ready: preloadContentUrlQuery(action.payload.routerRoute, action.payload.routerQuery),
      timeout: delay(10000),
    });
    if (raced.timeout) {
      console.log("timeout on preloadContentUrlQuery");
    }
  }

  // End saga if needed by the SSR
  if (action.payload?.withEndSaga) {
    yield put(END);
  }
}

function* initAuth(): Generator {
  yield put(authActions.initialize());
  yield take(authActions.setStatus);
}

function* initSite(domain: string): Generator {
  yield put(siteActions.initialize({ domain }));
  const action = (yield take(siteActions.setStatus)) as PayloadAction<SiteStatus>;
  return action.payload;
}

function* preloadContentUrlQuery(route: string, query: ParsedUrlQuery): Generator {
  // Homepage requires the list of quizzes
  if (route == "/") {
    yield put(quizzesActions.initialize());
    yield take(quizzesActions.setListStatus);
  }
  // Quiz page requires the current quiz
  else if (route.indexOf("/quiz/[slug]") == 0) {
    yield put(quizActions.initializeQuiz({ quizSlug: query.slug as string }));
    yield take(quizActions.setQuizStatus);
    if (route.indexOf("/quiz/[slug]/question/[question_slug]") == 0) {
      yield put(quizActions.setActiveQuestionSlug(query.question_slug as string));
    } else {
      yield put(quizActions.setActiveQuestionSlug());
    }
  }
  else if (route == "/flashcards") {
    yield put(flashcardsActions.initialize());
    yield take(flashcardsActions.setListStatus);
  }
}

export default function* sagas(): Generator {
  yield takeLatest(appActions.initialize, onInitialize);
}



export enum SiteStatus {
  NA = "NA",
  CONFIGURED = "CONFIGURED",
  ERROR = "ERROR",
  INVALID = "INVALID",
}

export interface SiteState {
  status: SiteStatus;
  code?: string;
  config: SiteConfig;
}

export const SiteCodeInvalid = "INVALID";


type BaseSiteConfig = {
  siteName: string,
  siteDomain: string,
  siteDescription: string,
  siteContentSummary: string,
  siteContentItems: string,
  siteContentDescription: string,
  siteAssetsBase: string,
  social: SocialConfig,
}

type SocialConfig = {
  twitterUsername?: string,
  facebookPageId?: string,
  instagramUsername?: string,

}
export type SiteConfig = BaseSiteConfig & Record<string, any>

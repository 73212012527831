import { ReduxState } from "@/redux";
import { FlashCard, FlashCardsListStatus } from "./types";

const flashcardsList = (state: ReduxState): FlashCard[] => {
  return state.flashcards.list;
};

const flashcardsCard = (state: ReduxState): FlashCard => {
  return state.flashcards.card;
};

const flashcardsListStatus = (state: ReduxState): FlashCardsListStatus => {
  return state.flashcards.listStatus;
};

const flashcardsHasMore = (state: ReduxState): boolean => {
  return state.flashcards.hasMore;
}


export const flashcardsSelectors = {
  flashcardsList,
  flashcardsCard,
  flashcardsListStatus,
  flashcardsHasMore,
}
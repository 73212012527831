
export const indexToABC = (id: number): string => {
  const startCharCode = "A".charCodeAt(0);
  return String.fromCharCode(startCharCode + id);
};

export function uuidv4(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

import { ReduxState } from "@/redux";
import { Quiz, QuizzesListStatus } from "./types";

const quizzesList = (state: ReduxState): Quiz[] => {
  return state.quizzes.list;
};

const quizzesListStatus = (state: ReduxState): QuizzesListStatus => {
  return state.quizzes.listStatus;
};


export const quizzesSelectors = {
  quizzesList,
  quizzesListStatus
}
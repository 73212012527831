import { CookieKey, CookieValue, Cookies } from "@/core/utils/cookies";
import { ReduxState } from "@/redux";
import { createSelector } from "@reduxjs/toolkit";

const requestCookies = (state: ReduxState): Cookies => {
  return state.context.requestCookies;
};

const requestCookie = (state: ReduxState, key: string): CookieValue => {
  return state.context.requestCookies[key];
};

const responseCookies = (state: ReduxState): Cookies => {
  return state.context.responseCookies;
};
const cookiesToDelete = createSelector(
  requestCookies,
  responseCookies,
  (requestCookies, responseCookies): CookieKey[] => {
    const cookiesToDelete: CookieKey[] = [];
    for (const key in requestCookies) {
      if (!responseCookies[key]) {
        cookiesToDelete.push(key);
      }
    }
    return cookiesToDelete;
  }
);

const cookiesToSet = createSelector(
  requestCookies,
  responseCookies,
  (requestCookies, responseCookies): Cookies => {
    const cookiesToSet: Cookies = {};
    for (const key in responseCookies) {
      if (requestCookies[key] !== responseCookies[key]) {
        cookiesToSet[key] = responseCookies[key];
      }
    }
    return cookiesToSet;
  }
);

export const contextSelectors = {
  requestCookies,
  requestCookie,
  responseCookies,
  cookiesToDelete,
  cookiesToSet,
};

import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { Action, PayloadAction } from "@reduxjs/toolkit";
import { InitializeQuizPayload, quizActions } from "./actions";
import { QuizDetails, QuizQuestion, QuizStatus } from "./types";
import { getApiClient } from "../api/sagas";
import { AxiosInstance } from "axios";
import { quizSelectors } from "./selectors";
import { progressActions } from "../progress/actions";
import { Quiz } from "../quizzes/types";
import { QuizProgressInfo } from "../progress/types";

function* onInitializeQuiz(action: PayloadAction<InitializeQuizPayload>): Generator {
  const { quizSlug } = action.payload;

  // Check if quiz needs to be loaded
  const quiz = (yield select(quizSelectors.quiz)) as QuizDetails;
  if (quiz && quiz.slug == quizSlug) {
    yield put(quizActions.setQuizStatus({ status: QuizStatus.LOADED }));
  } else {
    const api = (yield getApiClient()) as AxiosInstance;
    try {
      yield put(quizActions.reset());
      const data: any = yield call(api.get, `/api/quizzes/quizzes/${quizSlug}/`);
      const { questions } = data.data;
      yield put(quizActions.setQuiz(data.data as Quiz));
      yield put(quizActions.setQuestions(questions as QuizQuestion[]));
      yield put(quizActions.setQuizStatus({ status: QuizStatus.LOADED }));
    } catch (error: any) {
      console.error(error);
      yield put(quizActions.setQuizStatus({ status: QuizStatus.ERROR, code: error?.response?.status }));
    }
  }
}

function* onLoadRelatedQuizzes(): Generator {
  // !FIXME - reload only if the quiz is changed
  const api = (yield getApiClient()) as AxiosInstance;
  const quiz = (yield select(quizSelectors.quiz)) as Quiz;
  const relatedQuizzes = (yield select(quizSelectors.relatedQuizzes)) as Quiz[] | undefined;
  if (!quiz) return;

  if (relatedQuizzes) return;

  try {
    const data: any = yield call(api.get, `/api/quizzes/quizzes/?id__not_in=${quiz.id}&limit=2`);
    yield put(quizActions.setRelatedQuizzes(data.data?.results as Quiz[]));
  } catch (error) {
    console.error(error);
  }
}

function* onQuizProgressInfo(action: PayloadAction<QuizProgressInfo>): Generator {
  yield put(quizActions.setProgressInfo(action.payload));
}

export default function* sagas(): Generator {
  yield takeLatest(quizActions.initializeQuiz, onInitializeQuiz);
  yield takeLatest(quizActions.loadRelatedQuizzes, onLoadRelatedQuizzes);

  //When progress is changed on the current quizz, update the list of quizzes
  yield takeLatest(progressActions.quizProgressInfoUpdated, onQuizProgressInfo);
}

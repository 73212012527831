import axios, { AxiosInstance } from "axios";
import getConfig from "next/config";
import { Token, TokenType } from "../redux/store/auth/types";

const { publicRuntimeConfig } = getConfig();

type Headers = Record<string, string>;

export type ApiInstanceConfig = {
  token?: Token;
  siteCode?: string;
}

function _createApiInstance(customHeaders?: Headers) {
  const headers: Headers = {
    "x-client-build-id": publicRuntimeConfig.BUILD_ID,
    "x-client-build-date": publicRuntimeConfig.BUILD_DATE,
  };
  if (customHeaders) Object.assign(headers, customHeaders);

  const instance = axios.create({
    baseURL: publicRuntimeConfig.API_BASEURL,
    headers,
  });

  return instance;
}

export function createApiInstance(config?: ApiInstanceConfig): AxiosInstance {
  const headers: Headers = {};
  if (config?.token?.type == TokenType.USER) {
    headers["X-Authorization"] = `Bearer ${config?.token.token}`;
  } else if (config?.token?.type == TokenType.GUEST) {
    headers["X-Guest-Authorization"] = `Guest ${config?.token.token}`;
  }
  if (config?.siteCode) {
    headers["X-Site-Code"] = config.siteCode;
  }

  return _createApiInstance(headers);
}

import { createAction } from "@reduxjs/toolkit";
import { authReducerSlice } from "./slices";

export type InitializePayload = {
  forceGuest?: boolean;
} | undefined
const initialize = createAction<InitializePayload>("auth/initialize");

export const authActions = {
  initialize,
  ...authReducerSlice.actions,
};

export type FlashCard = {
  id: string;
  title: string;
  slug: string;
  sort_order: number;
  images?: {
    original?: string;
    original_blur?: string;
    width?: number;
    height?: number;
  };
}

export interface FlashCardsState {
  list: FlashCard[];
  listStatus: FlashCardsListStatus;
  hasMore: boolean;
  card?: FlashCard;
}
export enum FlashCardsListStatus {
  LOADING = "LOADING", // during loading
  LOADING_MORE = "LOADING_MORE", // during loading .... more
  LOADED = "LOADED",
  ERROR = "ERROR",
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthState, AuthStatus, Guest, Token, User } from "./types";

const getInitialState = (): AuthState => {
  return {
    status: AuthStatus.NA,
    user: undefined,
    guest: undefined,
    token: undefined,
  };
};

export const authReducerSlice = createSlice({
  name: "auth",
  initialState: getInitialState(),
  reducers: {
    setStatus(state, action: PayloadAction<AuthStatus>) {
      state.status = action.payload;
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setGuest(state, action: PayloadAction<Guest>) {
      state.guest = action.payload;
    },
    setToken(state, action: PayloadAction<Token>) {
      state.token = action.payload;
    },
  },
});

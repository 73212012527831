import { AnyAction, CombinedState, combineReducers } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { appReducerSlice } from "./app/slices";
import { quizzesReducerSlice } from "./quizzes/slices";
import { featuredReducerSlice } from "./featured/slices";
import { filtersReducerSlice } from "./filters/slices";
import { quizReducerSlice } from "./quiz/slices";
import { progressReducerSlice } from "./progress/slices";
import { authReducerSlice } from "./auth/slices";
import { contextReducerSlice } from "./context/slices";
import { routerReducerSlice } from "./router/slices";
import { siteReducerSlice } from "./site/slices";
import { flashcardsReducerSlice } from "./flashcards/slices";
import { isClient } from "@/core/utils/nextjs";

interface ReducerState {
  [appReducerSlice.name]: ReturnType<typeof appReducerSlice.reducer>;
  [quizzesReducerSlice.name]: ReturnType<typeof quizzesReducerSlice.reducer>;
  [featuredReducerSlice.name]: ReturnType<typeof featuredReducerSlice.reducer>;
  [filtersReducerSlice.name]: ReturnType<typeof filtersReducerSlice.reducer>;
  [quizReducerSlice.name]: ReturnType<typeof quizReducerSlice.reducer>;
  [progressReducerSlice.name]: ReturnType<typeof progressReducerSlice.reducer>;
  [authReducerSlice.name]: ReturnType<typeof authReducerSlice.reducer>;
  [contextReducerSlice.name]: ReturnType<typeof contextReducerSlice.reducer>;
  [routerReducerSlice.name]: ReturnType<typeof routerReducerSlice.reducer>;
  [siteReducerSlice.name]: ReturnType<typeof siteReducerSlice.reducer>;
  [flashcardsReducerSlice.name]: ReturnType<typeof flashcardsReducerSlice.reducer>;
}

const sliceReducer = {
  [appReducerSlice.name]: appReducerSlice.reducer,
  [quizzesReducerSlice.name]: quizzesReducerSlice.reducer,
  [featuredReducerSlice.name]: featuredReducerSlice.reducer,
  [filtersReducerSlice.name]: filtersReducerSlice.reducer,
  [quizReducerSlice.name]: quizReducerSlice.reducer,
  [progressReducerSlice.name]: progressReducerSlice.reducer,
  [authReducerSlice.name]: authReducerSlice.reducer,
  [contextReducerSlice.name]: contextReducerSlice.reducer,
  [routerReducerSlice.name]: routerReducerSlice.reducer,
  [siteReducerSlice.name]: siteReducerSlice.reducer,
  [flashcardsReducerSlice.name]: flashcardsReducerSlice.reducer,
};
export const rootReducer = (state: any, action: AnyAction): CombinedState<ReducerState> => {
  // Prevent setting app context in state
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };

    if (!isClient()) {
      // Custom server logic
    }

    return nextState;
  }

  return combineReducers(sliceReducer)(state, action);
};

import { AnyAction, Dispatch } from "@reduxjs/toolkit";

const getDate = () => {
  return new Date().toLocaleString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 3,
  });
};

const loggerMiddleware = (store: any) => (next: Dispatch) => (action: AnyAction) => {
  // if (action.type == HYDRATE && typeof window !== 'undefined') {
  //   console.log('hydrate', action.payload)
  // }
  // before reducers are executed
  let result = next(action);
  // console.log(result);
  // after reducers are executed
  // console.log('next state', store.getState())
  return result;
};

export const createLoggerMiddleware = () => {
  return loggerMiddleware;
};

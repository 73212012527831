import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { QuestionState, QuestionStatus } from "./types";
import { QuizQuestion } from "../quiz/types";


const getInitialState = (): QuestionState => {
  return {
    question: undefined,
    questionStatus: QuestionStatus.LOADING,
  };
};

export const featuredReducerSlice = createSlice({
  name: "featured",
  initialState: getInitialState(),
  reducers: {
    setQuestion: (state, action: PayloadAction<QuizQuestion>) => {
      state.question = action.payload;
    },
    setQuestionStatus: (state, action: PayloadAction<QuestionStatus>) => {
      state.questionStatus = action.payload;
    },
  },
});


import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProgressState, ProgressStatus } from "./types";

const getInitialState = (): ProgressState => {
  return {
    answersMap: {},
    status: ProgressStatus.STARTED,
    showExplanation: false,
  };
};

export const progressReducerSlice = createSlice({
  name: "progress",
  initialState: getInitialState(),
  reducers: {
    reset: (state) => {
      state.answersMap = {};
      state.status = ProgressStatus.STARTED;
      state.startedAt = new Date().toISOString();
    },
    clear: (state) => {
      state.answersMap = {};
      state.status = ProgressStatus.STARTED;
      state.startedAt = new Date().toISOString();
    },
    resume: (
      state,
      action: PayloadAction<{
        answersMap: ProgressState["answersMap"];
        status: ProgressState["status"];
      }>
    ) => {
      state.answersMap = action.payload.answersMap;
      state.status = action.payload.status;
    },
    complete: (state) => {
      state.status = ProgressStatus.FINISHED;
      state.finishedAt = new Date().toISOString();
    },
    setSingleAnswer: (
      state,
      action: PayloadAction<{ questionId: string; answerIds: string[] }>
    ) => {
      const { questionId, answerIds } = action.payload;
      state.answersMap = {
        ...state.answersMap,
        [questionId]: answerIds,
      };
    },
    toggleMultiAnswer: (
      state,
      action: PayloadAction<{ questionId: string; answerId: string }>
    ) => {
      const { questionId, answerId } = action.payload;
      let answerIds = state.answersMap[questionId] || [];
      if (answerIds.includes(answerId)) {
        answerIds = answerIds.filter((id) => id !== answerId);
      } else {
        answerIds = [...answerIds, answerId];
      }
      state.answersMap = {
        ...state.answersMap,
        [questionId]: answerIds,
      };
    },
    quizSetShowExplanation: (state, action: PayloadAction<boolean>) => {
      state.showExplanation = action.payload;
    },
  },
});

import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import { Provider } from "react-redux";
import { SagaStore, storeWrapper } from "@/redux";
import AppWrapper from "@/components/pages/app/AppWrapper";
import { ApiProvider } from "@/components/providers/ApiProvider";
import { useMemo } from "react";
import { createApiInstance } from "@/core/api/backend";
import { appActions } from "@/core/redux/store/app/actions";
import { deleteCookie, getCookies } from "@/core/utils/cookies";
import { contextActions } from "@/core/redux/store/context/actions";
import { contextSelectors } from "@/core/redux/store/context/selectors";
import { saveCookie } from "@/core/utils/cookies";
import { isServer } from "@/core/utils/nextjs";

import "@/styles/globals.css";
import "highlight.js/styles/github.css";
import { getHostname } from "@/core/utils/context";
import { AppStatus } from "@/core/redux/store/app/types";
import { SiteStatus } from "@/core/redux/store/site/types";

export function MainApp({ Component, ...rest }: AppProps) {
  const { store, props } = storeWrapper.useWrappedStore(rest);

  const apiClient = useMemo(() => {
    const token = store.getState().auth.token;
    const siteCode = store.getState().site.code;
    if (!siteCode) {
      console.error("No site code found in store");
    }
    return createApiInstance({token, siteCode});
  }, [store]);

  return (
    <ApiProvider client={apiClient}>
      <Provider store={store}>
        <AppWrapper>
          <Component {...props.pageProps} />
        </AppWrapper>
      </Provider>
    </ApiProvider>
  );
}

MainApp.getInitialProps = storeWrapper.getInitialAppProps((store: SagaStore) => async (appContext: AppContext) => {
  const startTime = Date.now();

  // Cookies - store in redux store
  const reqContext = appContext.ctx.req ? appContext.ctx : undefined;
  const cookies = getCookies(reqContext);
  const hostname = getHostname(reqContext);
  console.log(`### start ${appContext.ctx.pathname} on ${hostname}`);
  store.dispatch(contextActions.setInitialCookies(cookies));
  //Cookies - store in redux store - END
  // Init the core application
  store.dispatch(
    appActions.initialize({
      withEndSaga: isServer(),
      routerRoute: appContext.ctx.pathname,
      routerQuery: appContext.ctx.query,
      hostname: hostname,
    })
  );

  // Init the page app-props
  const pageProps = await App.getInitialProps(appContext);

  if (isServer() && appContext.ctx.res) {
    // On the server side we need to wait for the saga to end and cancel it
    await store.sagaTask.toPromise();
    store.sagaTask.cancel();

    if(store.getState().site.status === SiteStatus.INVALID) {
      appContext.ctx.res.statusCode = 404;
    }
    else if(store.getState().app.status === AppStatus.ERROR) {
      appContext.ctx.res.statusCode = 500;
    }
    else if (404 === store.getState().quiz.quizStatusCode) {
      appContext.ctx.res.statusCode = 404;
    }
  }

  // Cookies - delete/add browser cookies from redux store
  const cookiesToDelete = contextSelectors.cookiesToDelete(store.getState());
  cookiesToDelete.forEach((key) => {
    deleteCookie(reqContext, key);
  });
  const cookiesToAdd = contextSelectors.cookiesToSet(store.getState());
  Object.keys(cookiesToAdd).forEach((key) => {
    saveCookie(reqContext, key, cookiesToAdd[key]);
  });
  // Cookies - delete/add browser cookies from redux store - END
  // At this stage, the pageProps are finally mutated by redux

  console.log(`### end ${appContext.ctx.pathname} took ${Date.now() - startTime}ms`);

  return {
    pageProps: {
      // only way to pass the initialProps from the page
      initialProps: pageProps.pageProps.initialProps ?? {},
    },
  };
});

export default MainApp;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FiltersState } from "./types";

const getInitialState = (): FiltersState => {
  return {
    sorting: "-sort_order",
    difficulty: undefined,
    not_taken: false,
  };
};

export const filtersReducerSlice = createSlice({
  name: "filters",
  initialState: getInitialState(),
  reducers: {
    setSorting: (state, action: PayloadAction<string>) => {
      state.sorting = action.payload;
    },
    setDifficulty: (state, action: PayloadAction<string>) => {
      state.difficulty = action.payload;
    },
    setNotTaken: (state, action: PayloadAction<boolean>) => {
      state.not_taken = action.payload;
    },
    resetFilters: (state) => {
      const initial = getInitialState();
      state.sorting = initial.sorting;
      state.difficulty = initial.difficulty;
      state.not_taken = initial.not_taken;
    },
  },
});

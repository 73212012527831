import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SiteConfig, SiteState, SiteStatus } from "./types";

const getInitialState = (): SiteState => {
  return {
    status: SiteStatus.NA,
    code: undefined,
    config: {
      siteName: "n/a",
      siteDomain: "n/a",
      siteDescription: "n/a",
      siteContentItems: "n/a",
      siteContentSummary: "n/a",
      siteContentDescription: "n/a",
      siteAssetsBase: "/invalid",
      social: {
        twitterUsername: undefined,
        facebookPageId: undefined,
        instagramUsername: undefined,
      }
    },
  };
};

export const siteReducerSlice = createSlice({
  name: "site",
  initialState: getInitialState(),
  reducers: {
    setStatus(state, action: PayloadAction<SiteStatus>) {
      state.status = action.payload;
    },
    setCodeWithConfig(state, action: PayloadAction<{ code: string, config?: SiteConfig }>) {
      state.code = action.payload.code;
      if (action.payload.config) {
        state.config = action.payload.config;
      }
    }
  },
});

import { FC, PropsWithChildren, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { appSelectors } from "@/core/redux/store/app/selectors";
import { AppStatus } from "@/core/redux/store/app/types";
import { LoadingErrorPage } from "./AppWrapper/LoadingErrorPage";
import { AppLoading } from "./AppWrapper/AppLoading";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import { routerActions } from "@/core/redux/store/router/actions";
import { siteSelectors } from "@/core/redux/store/site/selectors";
import { SiteStatus } from "@/core/redux/store/site/types";
import { BadDomain } from "./AppWrapper/BadDomain";

const GoogleAnalyticsDynamic = dynamic(() => import("./AppWrapper/GoogleAnalytics"), {
  ssr: false,
  loading: () => null,
});

const AppWrapper: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const status = useSelector(appSelectors.appStatus);
  const siteStatus = useSelector(siteSelectors.siteStatus);
  const router = useRouter();

  useEffect(() => {
    dispatch(routerActions.setUrl(router.asPath));

    const routeChangeComplete = (url: string) => {
      dispatch(routerActions.setUrl(url));
    };

    router.events.on("routeChangeComplete", routeChangeComplete);
    return () => {
      router.events.off("routeChangeComplete", routeChangeComplete);
    };
  }, [router, dispatch]);

  if (siteStatus == SiteStatus.INVALID) return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Invalid Domain</title>
      </Head>
      <BadDomain />
    </>
  );
  else if (status == AppStatus.READY)
    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Head>
        {children}
        <GoogleAnalyticsDynamic />
      </>
    );
  else if (status == AppStatus.ERROR) return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Something went wrong</title>
      </Head>
      <LoadingErrorPage />
    </>
  )
  else return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <AppLoading />
    </>
  );
};

export default AppWrapper;

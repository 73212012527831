import { ReduxState } from "@/redux";
import { Quiz } from "../quizzes/types";
import { createSelector } from "@reduxjs/toolkit";
import { QuizAnswer, QuizQuestion, QuizStatus } from "./types";

const quiz = (state: ReduxState): Quiz | undefined => {
  return state.quiz.quiz;
};

const questions = (state: ReduxState): QuizQuestion[] => {
  return state.quiz.questions;
};

const relatedQuizzes = (state: ReduxState): Quiz[] | undefined => {
  return state.quiz.relatedQuizzes;
};

const questionsCount = (state: ReduxState): number => {
  return state.quiz.questions.length;
};

const quizStatus = (state: ReduxState): QuizStatus => {
  return state.quiz.quizStatus;
};
const quizStatusCode = (state: ReduxState): number | undefined => {
  return state.quiz.quizStatusCode;
};

const activeQuestionSlug = (state: ReduxState): string | undefined => {
  return state.quiz.activeQuestionSlug;
};

const question = createSelector(
  questions,
  activeQuestionSlug,
  (_questions, _activeQuestionSlug): QuizQuestion | undefined => {
    if (_activeQuestionSlug === undefined) {
      return _questions.length > 0 ? _questions[0] : undefined;
    }
    return _questions.find((question) => question.slug === _activeQuestionSlug);
  }
);

const questionIndex = createSelector(question, questions, (_question, _questions) => {
  if (_question === undefined) {
    return 0;
  }
  const index = _questions.findIndex((q: QuizQuestion) => q.id === _question.id);
  if (index === -1) {
    return 0;
  }
  return index;
});

const previousQuestion = createSelector(questionIndex, questions, (_index, _questions): QuizQuestion | undefined => {
  if (_index === undefined) {
    return undefined;
  }
  if (_index === 0) {
    return undefined;
  }
  return _questions[_index - 1];
});

const nextQuestion = createSelector(questionIndex, questions, (_index, _questions): QuizQuestion | undefined => {
  if (_index === undefined) {
    return undefined;
  }
  if (_index === _questions.length - 1) {
    return undefined;
  }
  return _questions[_index + 1];
});

const lastQuestion = createSelector(questions, (_questions): QuizQuestion | undefined => {
  if (_questions === undefined) {
    return undefined;
  }
  return _questions[_questions.length - 1];
});

const correctAnswers = createSelector(question, (_question): QuizAnswer[] => {
  if (_question === undefined) {
    return [];
  }
  return _question.answers.filter((answer: QuizAnswer) => !!answer.is_correct);
});

const correctAnswerIdxs = createSelector(question, (_question): number[] => {
  if (_question === undefined) {
    return [];
  }
  const correctIdxes: number[] = [];
  _question.answers.forEach((answer: QuizAnswer, index: number) => {
    if (answer.is_correct) {
      correctIdxes.push(index);
    }
  });
  return correctIdxes;
});

const currentQuestionPercent = createSelector(questionIndex, questionsCount, (_index, _questionsCount): number => {
  if (_index === undefined || _questionsCount === undefined) {
    return 0;
  }
  return ((_index + 1) / _questionsCount) * 100;
});

export const quizSelectors = {
  quiz,
  activeQuestionSlug,
  quizStatus,
  quizStatusCode,
  questions,
  questionsCount,
  relatedQuizzes,
  question,
  questionIndex,
  previousQuestion,
  nextQuestion,
  correctAnswers,
  correctAnswerIdxs,
  currentQuestionPercent,
  lastQuestion,
};
